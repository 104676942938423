import { formatInTimeZone } from "date-fns-tz";
import { th } from "date-fns/locale";

// format: ส. 22 ต.ค. 65 13:35:45
export function ConvertThaiDate(date: Date): string {
  if (typeof date !== "object") {
    return "";
  }

  const timeZone = "Asia/Bangkok";

  const THAI_DATE_TIME = formatInTimeZone(
    date,
    timeZone,
    "EEEEE dd MMM yyyy HH:mm:ss",
    {
      locale: th,
    }
  );

  return THAI_DATE_TIME;
}
