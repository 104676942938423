export function AllowInputDecimal(e: any) {
  let regex = new RegExp(/^[0-9.]+$/g);

  if (e.key === 'Delete' || e.key === 'Backspace') {
    return true;
  }
  if (e.key === '.') {
    const value = (e.target as HTMLInputElement).value;
    if (/\./g.test(value)) {
      e.preventDefault();
      return false;
    }

    return true;
  }
  if (regex.test(e.key)) {
    return true;
  }

  e.preventDefault();
  return false;
}

export default AllowInputDecimal;
