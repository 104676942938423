export function AllowInputNumber(e: any) {
  let regex = new RegExp(/^[0-9.]+$/g);

  if (e.key === 'Delete' || e.key === 'Backspace') {
    return true;
  }
  if (regex.test(e.key)) {
    return true;
  }

  e.preventDefault();
  return false;
}

export default AllowInputNumber;
