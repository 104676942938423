import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import { rhythm, scale } from 'src/utils';

import '../style.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Kanit'].join(','),
  },
});

type LayoutProps = {
  children: React.ReactNode;
};

export default function Layout(props: LayoutProps) {
  return (
    <>
      <Helmet>
        <title>โปรแกรมคำนวณทุกอย่างออนไลน์ — Calculate Anything</title>
        <meta
          name="description"
          content="ตัวช่วยคำนวณทุกอย่างออนไลน์ ประกอบการตัดสินใจ"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <main>
          <div style={{ minHeight: '90vh' }}>{props.children}</div>

          <footer style={{ marginTop: rhythm(1), paddingBottom: rhythm(0.1) }}>
            <p
              style={{
                textAlign: 'center',
                ...scale(-2 / 5),
              }}
            >
              © 2021. All Right Reserved. Made by{' '}
              <a
                href="https://ohmpiromrak.com"
                style={{ color: '#000', textDecoration: 'underline' }}
              >
                Ohm Piromrak
              </a>
            </p>
          </footer>
        </main>
      </ThemeProvider>
    </>
  );
}
