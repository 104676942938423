// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bmi-calculator-tsx": () => import("./../../../src/pages/bmi-calculator.tsx" /* webpackChunkName: "component---src-pages-bmi-calculator-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-risk-to-reward-ratio-calculator-tsx": () => import("./../../../src/pages/risk-to-reward-ratio-calculator.tsx" /* webpackChunkName: "component---src-pages-risk-to-reward-ratio-calculator-tsx" */),
  "component---src-pages-vehicle-installment-calculator-tsx": () => import("./../../../src/pages/vehicle-installment-calculator.tsx" /* webpackChunkName: "component---src-pages-vehicle-installment-calculator-tsx" */)
}

